import { Container, Toolbar } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import SearchAppBar from "../Components/AppBar";
import ImageList from "../Components/ImagesList";
import SortAndFilters from "../Components/SortAndFilters";
import { allCompaniesArray } from "../Consts/Consts";
import { itemsDemo } from "../Consts/DemoData";
import { ComapnyType } from "../Types/Types";

const HomePage = () => {
  const [companiesToShow, setCompaniesToShow] = useState<Set<ComapnyType>>(
    new Set(allCompaniesArray)
  );

  const removeComapny = (companyToDelete: ComapnyType) => {
    const clone = new Set(companiesToShow);
    clone.delete(companyToDelete);
    setCompaniesToShow(clone);
  };

  const addCompany = (newCompany: ComapnyType) => {
    const clone = new Set(companiesToShow);
    clone.add(newCompany);
    setCompaniesToShow(clone);
  };

  return (
    <>
      <SearchAppBar />
      <Toolbar variant="dense" />
      <SortAndFilters
        companiesToShow={companiesToShow}
        removeComapny={removeComapny}
        addCompany={addCompany}
      />
      <Divider />
      <Container sx={{ overflow: "auto" }}>
        <ImageList
          items={itemsDemo.filter((item) => companiesToShow.has(item.company))}
        />
      </Container>
    </>
  );
};

export default HomePage;
