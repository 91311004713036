import { Chip } from "@mui/material";
import Box from "@mui/material/Box/Box";
import { allCompaniesArray, companyDic } from "../Consts/Consts";
import { ComapnyType } from "../Types/Types";

type SortAndFiltersProps = {
  companiesToShow: Set<ComapnyType>;
  removeComapny: (companyToDelete: ComapnyType) => void;
  addCompany: (newCompany: ComapnyType) => void;
};

const SortAndFilters = ({
  companiesToShow,
  removeComapny,
  addCompany,
}: SortAndFiltersProps) => {
  return (
    <Box
      display={"flex"}
      alignItems="center"
      justifyContent={"center"}
      gap={1}
      py={0.5}
      flexWrap="wrap"
    >
      {allCompaniesArray.map((company, index) => {
        const active = companiesToShow.has(company);
        return (
          <Chip
            key={`company-chip-${index}`}
            label={companyDic[company]}
            variant={active ? "filled" : "outlined"}
            size="small"
            color={active ? "success" : "default"}
            clickable
            onClick={() => {
              active ? removeComapny(company) : addCompany(company);
            }}
          />
        );
      })}
    </Box>
  );
};

export default SortAndFilters;
