import { Box } from "@mui/material";
import HomePage from "./Pages/HomePage";

function App() {
  return (
    <Box
      id="app-wrapper"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <HomePage />
    </Box>
  );
}

export default App;
