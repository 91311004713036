import { ComapnyType } from "../Types/Types";
import castroLogo from "../Assets/Images/castro-logo.webp";
import foxLogo from "../Assets/Images/fox-logo.webp";
import hmLogo from "../Assets/Images/hm-logo.webp";
import renuarLogo from "../Assets/Images/renuar-logo.webp";
import urbanicaLogo from "../Assets/Images/urbanica-logo.webp";
import mangoLogo from "../Assets/Images/mango-logo.webp";
import twentyFourSevenLogo from "../Assets/Images/twentyfourseven-logo.webp";
import Box from "@mui/material/Box/Box";

type CompanyLogoProps = { companyName: ComapnyType };

const CompanyLogo = ({ companyName }: CompanyLogoProps) => {
  let imgSrc = "";
  switch (companyName) {
    case "CASTRO":
      imgSrc = castroLogo;
      break;
    case "FOX":
      imgSrc = foxLogo;
      break;
    case "H_M":
      imgSrc = hmLogo;
      break;
    case "RENUAR":
      imgSrc = renuarLogo;
      break;
    case "URBANICA":
      imgSrc = urbanicaLogo;
      break;
    case "MANGO":
      imgSrc = mangoLogo;
      break;
    case "TWENTYFOURSEVEN":
      imgSrc = twentyFourSevenLogo;
      break;
    default:
      imgSrc = "fallback";
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" p={0.25}>
      <Box
        component="img"
        sx={{
          height: 30,
          maxHeight: 30,
          maxWidth: "100%",
          objectFit: "contain",
        }}
        alt=""
        src={imgSrc}
      />
    </Box>
  );
};

export default CompanyLogo;
