import { ComapnyType } from "../Types/Types";

export const allCompaniesArray: ComapnyType[] = [
  "CASTRO",
  "FOX",
  "RENUAR",
  "H_M",
  "MANGO",
  "URBANICA",
  "TWENTYFOURSEVEN",
];

export const companyDic: { [company in ComapnyType]: string } = {
  CASTRO: "Castro",
  FOX: "Fox",
  RENUAR: "Renuar",
  H_M: "H&M",
  MANGO: "Mango",
  URBANICA: "Urbanica",
  TWENTYFOURSEVEN: "Twentyfourseven",
};
