import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import CompanyLogo from "./CompanyLogo";
import { ItemType } from "../Types/Types";
import Link from "@mui/material/Link";

type ImageListProps = {
  items: ItemType[];
};
const ImageList = ({ items }: ImageListProps) => {
  return (
    <Box flexGrow={1} py={2}>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        {items.map((item, index) => {
          const percentage =
            (-1 * (item.old_price - item.new_price)) / item.old_price;
          return (
            <Grid key={`item-grid-${index}`} item xs={6} md={3}>
              <Card sx={{ height: "100%" }}>
                <CompanyLogo companyName={item.company} />

                <CardMedia
                  component="img"
                  image={item.picture_links[0]}
                  alt={item.name}
                  loading="lazy"
                />
                <CardContent
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    p: 1,
                  }}
                >
                  <Typography variant="h6" sx={{ direction: "rtl" }}>
                    {item.name}
                  </Typography>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    gap={{ xs: 0.5, md: 1 }}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Chip
                      size="small"
                      label={percentage.toLocaleString(undefined, {
                        style: "percent",
                        minimumFractionDigits: 0,
                      })}
                      color="primary"
                    />
                    <Typography color="success.main" variant="h6">
                      {item.new_price.toLocaleString(undefined, {
                        style: "currency",
                        currency: "ILS",
                      })}
                    </Typography>
                    <Typography
                      color="error"
                      sx={{ textDecoration: "line-through" }}
                    >
                      {item.old_price.toLocaleString(undefined, {
                        style: "currency",
                        currency: "ILS",
                      })}
                    </Typography>
                  </Stack>
                  <Link href={item.link} target="_blank" rel="noopener">
                    Visit
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ImageList;
