import { ItemType } from "../Types/Types";

export const itemsDemo: ItemType[] = [
  {
    name: " שמלת מיני מכופתרת ",
    link: "https://www.castro.com/61104770100",
    new_price: 149.9,
    old_price: 179.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/1/6110477.01.0100-1715714417462015.jpg",
    ],
    company: "MANGO",
    category: "DRESS",
  },
  {
    name: " שמלת מיני עם כיווצים ",
    link: "https://www.castro.com/61105049024",
    new_price: 149.9,
    old_price: 179.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/1/6110504.01.9024-1718824814638146.jpg",
    ],
    company: "URBANICA",
    category: "DRESS",
  },
  {
    name: " שמלת מיני מעטפת ",
    link: "https://www.castro.com/61105031018",
    new_price: 149.9,
    old_price: 179.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/1/6110503.01.1018-1714591215954321.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " טופ עם גב פתוח ",
    link: "https://www.castro.com/71116770100",
    new_price: 79.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/1/7111677.01.0100-1718220025514777.jpg",
    ],
    company: "RENUAR",
    category: "SHIRTS",
  },
  {
    name: " שמלת מידי ג’ינס ",
    link: "https://www.castro.com/6j101200004",
    new_price: 149.9,
    old_price: 229.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/j/6j10120.01.0004-1713122420552248.jpg",
    ],
    company: "FOX",
    category: "DRESS",
  },
  {
    name: " שמלת מיני מעטפת ",
    link: "https://www.castro.com/61105036600",
    new_price: 149.9,
    old_price: 179.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/1/6110503.01.6600-1714591215735677.jpg",
    ],
    company: "H_M",
    category: "DRESS",
  },
  {
    name: " שמלת מיני עם כיווצים ",
    link: "https://www.castro.com/61105047017",
    new_price: 149.9,
    old_price: 179.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/1/6110504.01.7017-1718824814736796.jpg",
    ],
    company: "TWENTYFOURSEVEN",
    category: "DRESS",
  },
  {
    name: " שמלת מיני מכופתרת ",
    link: "https://www.castro.com/61104770328",
    new_price: 149.9,
    old_price: 179.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/1/6110477.01.0328-1715714417584458.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " שמלת מקסי מתרחבת ",
    link: "https://www.castro.com/65102940100",
    new_price: 99.9,
    old_price: 299.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/5/6510294.01.0100-1713295216404164.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " שמלת מקסי רחבה ",
    link: "https://www.castro.com/65103100100",
    new_price: 149.9,
    old_price: 229.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/5/6510310.01.0100-1719084021687223.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " טופ סטרפלס ",
    link: "https://www.castro.com/83103630100",
    new_price: 79.9,
    old_price: 149.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/8/3/8310363.01.0100-1716319237685737.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " מכנסיים קצרים ",
    link: "https://www.castro.com/34102260100",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/3/4/3410226.01.0100-1716319212460905.jpg",
    ],
    company: "CASTRO",
    category: "PANTS",
  },
  {
    name: " קימונו רקום ",
    link: "https://www.castro.com/86106500100",
    new_price: 99.9,
    old_price: 259.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/8/6/8610650.01.0100-1712172048558231.jpg",
    ],
    company: "CASTRO",
    category: null,
  },
  {
    name: " סנדלי פלטפורמה ",
    link: "https://www.castro.com/f5102360100",
    new_price: 149.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/f/5/f510236.01.0100-1718824845429385.jpg",
    ],
    company: "CASTRO",
    category: "SHOES",
  },
  {
    name: " שמלת מידי ג’ינס ",
    link: "https://www.castro.com/6j101200002",
    new_price: 149.9,
    old_price: 229.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/j/6j10120.01.0002-1713122420399498.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " שמלת מקסי עם טופ אתני ",
    link: "https://www.castro.com/65103360100",
    new_price: 149.9,
    old_price: 269.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/5/6510336.01.0100-1711225225406608.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " מכנסיים קצרים עם רקמה ",
    link: "https://www.castro.com/34102159400",
    new_price: 79.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/3/4/3410215.01.9400-1714245605369585.jpg",
    ],
    company: "CASTRO",
    category: "PANTS",
  },
  {
    name: " שמלת סטרפלס פאייטים ",
    link: "https://www.castro.com/65102929009",
    new_price: 149.9,
    old_price: 299.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/5/6510292.01.9009-1713208815882408.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " חצאית מעטפת פאייטים ",
    link: "https://www.castro.com/55102280358",
    new_price: 149.9,
    old_price: 229.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/5/5/5510228.01.0358-1704572431694003.jpg",
    ],
    company: "CASTRO",
    category: "SKIRTS",
  },
  {
    name: " חולצה עם רקמה ",
    link: "https://www.castro.com/86106489400",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/8/6/8610648.01.9400-1715714437776082.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " סנדלי עקב ",
    link: "https://www.castro.com/f1101060100",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/f/1/f110106.01.0100-1715714438390048.jpg",
    ],
    company: "CASTRO",
    category: "SHOES",
  },
  {
    name: " מכנסי CARGO עם גומי ",
    link: "https://www.castro.com/30105209002",
    new_price: 49.9,
    old_price: 189.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/3/0/3010520.01.9002-1707942012431401.jpg",
    ],
    company: "CASTRO",
    category: "PANTS",
  },
  {
    name: " מכנסי ג’ינס ברמודה ",
    link: "https://www.castro.com/24101490169",
    new_price: 79.9,
    old_price: 149.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/2/4/2410149.01.0169-1713727203400095.jpg",
    ],
    company: "CASTRO",
    category: "JEANS",
  },
  {
    name: " שמלת מקסי ",
    link: "https://www.castro.com/65103070100",
    new_price: 149.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/5/6510307.01.0100-1716751220753710.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " חולצה מכופתרת קצרה ",
    link: "https://www.castro.com/83103789002",
    new_price: 79.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/8/3/8310378.01.9002-1715628039666231.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " מכנסיים קצרים ",
    link: "https://www.castro.com/34101990100",
    new_price: 79.9,
    old_price: 149.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/3/4/3410199.01.0100-1716405615403564.jpg",
    ],
    company: "CASTRO",
    category: "PANTS",
  },
  {
    name: " חולצה מכופתרת קצרה ",
    link: "https://www.castro.com/83103789024",
    new_price: 79.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/8/3/8310378.01.9024-1715628039776387.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " חצאית סקורט ",
    link: "https://www.castro.com/54102279024",
    new_price: 79.9,
    old_price: 159.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/5/4/5410227.01.9024-1716405621372889.jpg",
    ],
    company: "CASTRO",
    category: "SKIRTS",
  },
  {
    name: " שמלת מקסי ",
    link: "https://www.castro.com/65103070359",
    new_price: 149.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/5/6510307.01.0359-1716751221771342.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " שמלת מיני סטרפלס ",
    link: "https://www.castro.com/61104471121",
    new_price: 149.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/1/6110447.01.1121-1715714417557039.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " טי-שירט עם רקמה ",
    link: "https://www.castro.com/71117880500",
    new_price: 79.9,
    old_price: 119.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/1/7111788.01.0500-1718479233881028.jpg",
    ],
    company: "CASTRO",
    category: null,
  },
  {
    name: " מכנסי ג’ינס קצרים ",
    link: "https://www.castro.com/24101440002",
    new_price: 49.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/2/4/2410144.01.0002-1717442407526455.jpg",
    ],
    company: "CASTRO",
    category: "JEANS",
  },
  {
    name: " חצאית מיני מעטפת ",
    link: "https://www.castro.com/54102636600",
    new_price: 99.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/5/4/5410263.01.6600-1715714416521245.jpg",
    ],
    company: "CASTRO",
    category: "SKIRTS",
  },
  {
    name: " מכנסי ג’ינס קצרים ",
    link: "https://www.castro.com/24101440004",
    new_price: 49.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/2/4/2410144.01.0004-1717442408492145.jpg",
    ],
    company: "CASTRO",
    category: "JEANS",
  },
  {
    name: " מכנסיים קצרים ",
    link: "https://www.castro.com/34101999210",
    new_price: 79.9,
    old_price: 149.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/3/4/3410199.01.9210-1716405616380919.jpg",
    ],
    company: "CASTRO",
    category: "PANTS",
  },
  {
    name: " גופיית סאטן ",
    link: "https://www.castro.com/83103169024",
    new_price: 29.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/8/3/8310316.01.9024-1714418419606900.jpg",
    ],
    company: "CASTRO",
    category: "TANKTOP",
  },
  {
    name: " ג’ינס Jogger קצר ",
    link: "https://www.castro.com/24101510192",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/2/4/2410151.01.0192-1713813603497732.jpg",
    ],
    company: "CASTRO",
    category: "JEANS",
  },
  {
    name: " מכנסיים קצרים ג'ינס ",
    link: "https://www.castro.com/24101350192",
    new_price: 79.9,
    old_price: 149.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/2/4/2410135.01.0192-1716405605822525.jpg",
    ],
    company: "CASTRO",
    category: "JEANS",
  },
  {
    name: " חולצה עם שרוולים ארוכים וצווארון מסולסל ",
    link: "https://www.castro.com/72104671097",
    new_price: 29.9,
    old_price: 79.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/2/7210467.01.1097-1707164447457002.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " שמלת מידי מתרחבת ",
    link: "https://www.castro.com/65103630100",
    new_price: 149.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/5/6510363.01.0100-1715714421360484.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " מכנסי CARGO עם גומי ",
    link: "https://www.castro.com/30105200300",
    new_price: 49.9,
    old_price: 189.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/3/0/3010520.01.0300-1707942012471086.jpg",
    ],
    company: "CASTRO",
    category: "PANTS",
  },
  {
    name: " תיק צד קש ",
    link: "https://www.castro.com/b7101641028",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/b/7/b710164.01.1028-1712776847730475.jpg",
    ],
    company: "CASTRO",
    category: "ACCESSORIES",
  },
  {
    name: " גופיית סאטן ",
    link: "https://www.castro.com/83103160358",
    new_price: 29.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/8/3/8310316.01.0358-1714418419922367.jpg",
    ],
    company: "CASTRO",
    category: "TANKTOP",
  },
  {
    name: " מכנסיים קצרים ",
    link: "https://www.castro.com/34102230100",
    new_price: 99.9,
    old_price: 159.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/3/4/3410223.01.0100-1715196009400388.jpg",
    ],
    company: "CASTRO",
    category: "PANTS",
  },
  {
    name: " סווטשירט עם הדפס קידמי ",
    link: "https://www.castro.com/73104330200",
    new_price: 29.9,
    old_price: 159.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/3/7310433.01.0200-1704226847709611.jpg",
    ],
    company: "CASTRO",
    category: null,
  },
  {
    name: " טי-שירט עם רקמה ",
    link: "https://www.castro.com/71117889024",
    new_price: 79.9,
    old_price: 119.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/1/7111788.01.9024-1718479233805191.jpg",
    ],
    company: "CASTRO",
    category: null,
  },
  {
    name: " חצאית מקסי ",
    link: "https://www.castro.com/56101020100",
    new_price: 149.9,
    old_price: 249.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/5/6/5610102.01.0100-1714591213480086.jpg",
    ],
    company: "CASTRO",
    category: "SKIRTS",
  },
  {
    name: " חולצה עם שרוולים ארוכים וצווארון מסולסל ",
    link: "https://www.castro.com/72104670100",
    new_price: 29.9,
    old_price: 79.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/2/7210467.01.0100-1707164446283297.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " מכנסי בד קשירה ",
    link: "https://www.castro.com/30105020100",
    new_price: 99.9,
    old_price: 179.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/3/0/3010502.01.0100-1711225210461474.jpg",
    ],
    company: "CASTRO",
    category: "PANTS",
  },
  {
    name: " חצאית מידי עם רקמה ",
    link: "https://www.castro.com/56101210319",
    new_price: 149.9,
    old_price: 249.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/5/6/5610121.01.0319-1711311618412732.jpg",
    ],
    company: "CASTRO",
    category: "SKIRTS",
  },
  {
    name: " חולצה עם תפר קדמי באמצע ",
    link: "https://www.castro.com/73104389024",
    new_price: 29.9,
    old_price: 149.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/3/7310438.01.9024-1707596444856155.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " שמלת מיני עם רקמה ",
    link: "https://www.castro.com/61104704400",
    new_price: 149.9,
    old_price: 269.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/1/6110470.01.4400-1718738417724701.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " כפכפים סגורים ",
    link: "https://www.castro.com/f6101109028",
    new_price: 49.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/f/6/f610110.01.9028-1715628042537049.jpg",
    ],
    company: "CASTRO",
    category: "SHOES",
  },
  {
    name: " שמלת מקסי רחבה ",
    link: "https://www.castro.com/65103100328",
    new_price: 149.9,
    old_price: 229.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/5/6510310.01.0328-1719084021452715.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " מכנסיים קצרים ",
    link: "https://www.castro.com/34102230400",
    new_price: 99.9,
    old_price: 159.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/3/4/3410223.01.0400-1715196009441427.jpg",
    ],
    company: "CASTRO",
    category: "PANTS",
  },
  {
    name: " גופייה אבנים ",
    link: "https://www.castro.com/48101158900",
    new_price: 99.9,
    old_price: 119.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/4/8/4810115.01.8900-1714591211901869.jpg",
    ],
    company: "CASTRO",
    category: "TANKTOP",
  },
  {
    name: " חצאית מיני פרחונית ",
    link: "https://www.castro.com/54102140136",
    new_price: 49.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/5/4/5410214.01.0136-1716405621449037.jpg",
    ],
    company: "CASTRO",
    category: "SKIRTS",
  },
  {
    name: " שמלת מידי מתרחבת ",
    link: "https://www.castro.com/65103637025",
    new_price: 149.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/5/6510363.01.7025-1715714421372545.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " נעלי בובה סרוגות ",
    link: "https://www.castro.com/f3102007600",
    new_price: 79.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/f/3/f310200.01.7600-1713813635470029.jpg",
    ],
    company: "CASTRO",
    category: "SHOES",
  },
  {
    name: " גופייה עם מפתח סירה ",
    link: "https://www.castro.com/70103300100",
    new_price: 79.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/0/7010330.01.0100-1710879628545800.jpg",
    ],
    company: "CASTRO",
    category: "TANKTOP",
  },
  {
    name: " תיק צד מרובע ",
    link: "https://www.castro.com/b9101493200",
    new_price: 79.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/b/9/b910149.01.3200-1709583652269766.jpg",
    ],
    company: "CASTRO",
    category: "ACCESSORIES",
  },
  {
    name: " טי-שירט עם רקמה ",
    link: "https://www.castro.com/71117940500",
    new_price: 79.9,
    old_price: 119.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/1/7111794.01.0500-1718479234758697.jpg",
    ],
    company: "CASTRO",
    category: null,
  },
  {
    name: " גופייה עם רקמה ",
    link: "https://www.castro.com/83103440319",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/8/3/8310344.01.0319-1711311649576198.jpg",
    ],
    company: "CASTRO",
    category: "TANKTOP",
  },
  {
    name: " שמלת מקסי מתרחבת ",
    link: "https://www.castro.com/65102959400",
    new_price: 149.9,
    old_price: 299.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/5/6510295.01.9400-1712604017381979.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " סווטשירט בוקסי ",
    link: "https://www.castro.com/73104102100",
    new_price: 39.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/3/7310410.01.2100-1704918026401105.jpg",
    ],
    company: "CASTRO",
    category: null,
  },
  {
    name: " שמלת מעטפת עם רקמה ",
    link: "https://www.castro.com/61104449400",
    new_price: 149.9,
    old_price: 229.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/1/6110444.01.9400-1711311619354723.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " חצאית מיני ",
    link: "https://www.castro.com/54102620100",
    new_price: 79.9,
    old_price: 149.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/5/4/5410262.01.0100-1715628014496199.jpg",
    ],
    company: "CASTRO",
    category: "SKIRTS",
  },
  {
    name: " מכנסי שיפון קצרים ",
    link: "https://www.castro.com/34101836600",
    new_price: 49.9,
    old_price: 169.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/3/4/3410183.01.6600-1714591206509855.jpg",
    ],
    company: "CASTRO",
    category: "PANTS",
  },
  {
    name: " חצאית מיני פרחונית ",
    link: "https://www.castro.com/54102140100",
    new_price: 49.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/5/4/5410214.01.0100-1716405620449286.jpg",
    ],
    company: "CASTRO",
    category: "SKIRTS",
  },
  {
    name: " נעלי מוקסין שטוחות ",
    link: "https://www.castro.com/f3102343000",
    new_price: 79.9,
    old_price: 169.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/f/3/f310234.01.3000-1716146435413719.jpg",
    ],
    company: "CASTRO",
    category: "SHOES",
  },
  {
    name: " מכנסי ג’ינס קצרים ",
    link: "https://www.castro.com/24101442001",
    new_price: 49.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/2/4/2410144.01.2001-1717442408513875.jpg",
    ],
    company: "CASTRO",
    category: "JEANS",
  },
  {
    name: " תיק צד קטן ",
    link: "https://www.castro.com/b9101431121",
    new_price: 79.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/b/9/b910143.01.1121-1704500461534603.jpg",
    ],
    company: "CASTRO",
    category: "ACCESSORIES",
  },
  {
    name: " נעלי מוקסין שטוחות ",
    link: "https://www.castro.com/f3102340100",
    new_price: 79.9,
    old_price: 169.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/f/3/f310234.01.0100-1716146435435188.jpg",
    ],
    company: "CASTRO",
    category: "SHOES",
  },
  {
    name: " תיק צד דמוי עור ",
    link: "https://www.castro.com/b9101600100",
    new_price: 79.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/b/9/b910160.01.0100-1709583652115673.jpg",
    ],
    company: "CASTRO",
    category: "ACCESSORIES",
  },
  {
    name: " כפכפי מוקסין דמוי עור ",
    link: "https://www.castro.com/f3102053000",
    new_price: 79.9,
    old_price: 169.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/f/3/f310205.01.3000-1713813635291924.jpg",
    ],
    company: "CASTRO",
    category: "SHOES",
  },
  {
    name: " חצאית מיני ",
    link: "https://www.castro.com/54102620447",
    new_price: 79.9,
    old_price: 149.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/5/4/5410262.01.0447-1715628015575768.jpg",
    ],
    company: "CASTRO",
    category: "SKIRTS",
  },
  {
    name: " בגד גוף עם קאט-אאוט ",
    link: "https://www.castro.com/7b101364004",
    new_price: 29.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/b/7b10136.01.4004-1710620456860266.jpg",
    ],
    company: "CASTRO",
    category: "LEOTARD",
  },
  {
    name: " גופייה בטקסטורה ",
    link: "https://www.castro.com/70103200100",
    new_price: 49.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/0/7010320.01.0100-1710706827540328.jpg",
    ],
    company: "CASTRO",
    category: "TANKTOP",
  },
  {
    name: " חולצת דיטיילס רקמה ",
    link: "https://www.castro.com/86106499400",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/8/6/8610649.01.9400-1713036043760807.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " כפכפי מוקסין ",
    link: "https://www.castro.com/f3102360100",
    new_price: 79.9,
    old_price: 169.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/f/3/f310236.01.0100-1717528848388101.jpg",
    ],
    company: "CASTRO",
    category: "SHOES",
  },
  {
    name: " בגד גוף ללא תפרים ",
    link: "https://www.castro.com/7b101330300",
    new_price: 29.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/b/7b10133.01.0300-1707524448407600.jpg",
    ],
    company: "CASTRO",
    category: "LEOTARD",
  },
  {
    name: " כפכפים סגורים ",
    link: "https://www.castro.com/f6101109024",
    new_price: 49.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/f/6/f610110.01.9024-1715628042429116.jpg",
    ],
    company: "CASTRO",
    category: "SHOES",
  },
  {
    name: " טי-שירט עם רקמה ",
    link: "https://www.castro.com/71117860100",
    new_price: 79.9,
    old_price: 119.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/1/7111786.01.0100-1716319229485139.jpg",
    ],
    company: "CASTRO",
    category: null,
  },
  {
    name: " חולצה סרוגה עם אבנים ",
    link: "https://www.castro.com/43100500500",
    new_price: 99.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/4/3/4310050.01.0500-1714591210835513.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " בגד גוף ",
    link: "https://www.castro.com/7b101480100",
    new_price: 99.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/b/7b10148.01.0100-1715196033653867.jpg",
    ],
    company: "CASTRO",
    category: "LEOTARD",
  },
  {
    name: " שמלת מיני כיווצים עם פרח ",
    link: "https://www.castro.com/61104370100",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/1/6110437.01.0100-1704572432730834.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " חצאית מיני ",
    link: "https://www.castro.com/54102620438",
    new_price: 79.9,
    old_price: 149.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/5/4/5410262.01.0438-1715628015551207.jpg",
    ],
    company: "CASTRO",
    category: "SKIRTS",
  },
  {
    name: " חולצה מבד דק וצווארון גבוה ",
    link: "https://www.castro.com/72104800600",
    new_price: 29.9,
    old_price: 79.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/2/7210480.01.0600-1707855645749869.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " חולצה מבד דק וצווארון גבוה ",
    link: "https://www.castro.com/72104809024",
    new_price: 29.9,
    old_price: 79.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/2/7210480.01.9024-1707855645832511.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " נעלי בובה סרוגות ",
    link: "https://www.castro.com/f3102000100",
    new_price: 79.9,
    old_price: 99.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/f/3/f310200.01.0100-1713813634359850.jpg",
    ],
    company: "CASTRO",
    category: "SHOES",
  },
  {
    name: " שמלת מיני עם רקמה ",
    link: "https://www.castro.com/61104700319",
    new_price: 149.9,
    old_price: 269.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/1/6110470.01.0319-1718738417375985.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " חולצה סרוגה עם אבנים ",
    link: "https://www.castro.com/43100509024",
    new_price: 99.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/4/3/4310050.01.9024-1714591210999415.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " בגד גוף ",
    link: "https://www.castro.com/7b101480328",
    new_price: 99.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/b/7b10148.01.0328-1715196034755530.jpg",
    ],
    company: "CASTRO",
    category: "LEOTARD",
  },
  {
    name: " בגד גוף ",
    link: "https://www.castro.com/7b101481097",
    new_price: 99.9,
    old_price: 129.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/b/7b10148.01.1097-1715196034725018.jpg",
    ],
    company: "CASTRO",
    category: "LEOTARD",
  },
  {
    name: " חולצת צווארון וי ורקמות ",
    link: "https://www.castro.com/82104080319",
    new_price: 79.9,
    old_price: 149.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/8/2/8210408.01.0319-1711311646750558.jpg",
    ],
    company: "CASTRO",
    category: "SHIRTS",
  },
  {
    name: " שמלת מיני פרחונית ",
    link: "https://www.castro.com/61104760380",
    new_price: 149.9,
    old_price: 269.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/1/6110476.01.0380-1712085624687424.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " תיק בטקסטורה מנוחשת ",
    link: "https://www.castro.com/b7101400100",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/b/7/b710140.01.0100-1709583651472018.jpg",
    ],
    company: "CASTRO",
    category: "ACCESSORIES",
  },
  {
    name: " סווטשירט עם רוכסן ",
    link: "https://www.castro.com/76100854004",
    new_price: 99.9,
    old_price: 189.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/7/6/7610085.01.4004-1705436442467485.jpg",
    ],
    company: "CASTRO",
    category: null,
  },
  {
    name: " תיק ג’ינס ",
    link: "https://www.castro.com/b9101402000",
    new_price: 79.9,
    old_price: 149.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/b/9/b910140.01.2000-1704500461251558.jpg",
    ],
    company: "CASTRO",
    category: "JEANS",
  },
  {
    name: " נעל עקב עם רצועת גומי ",
    link: "https://www.castro.com/fa100010414",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/f/a/fa10001.01.0414-1702239644181842.jpg",
    ],
    company: "CASTRO",
    category: "SHOES",
  },
  {
    name: " שמלת מקסי מתרחבת ",
    link: "https://www.castro.com/65102940319",
    new_price: 99.9,
    old_price: 299.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/5/6510294.01.0319-1711311622344922.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: " שמלת מקסי ",
    link: "https://www.castro.com/65103611018",
    new_price: 149.9,
    old_price: 199.9,
    picture_links: [
      "https://www.castro.com/pub/media/catalog/product/cache/bc184199e1aec61bc55960dec774faa9/6/5/6510361.01.1018-1715628020637741.jpg",
    ],
    company: "CASTRO",
    category: "DRESS",
  },
  {
    name: "שמלת  מיני מתוקה",
    link: "https://www.renuar.co.il/women/clothing/dresses/113500002.html?page=women",
    new_price: 99.9,
    old_price: 139.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw2bb1e011/images/large/113500002/113500002-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "שמלת  מיני מתוקה",
    link: "https://www.renuar.co.il/women/clothing/dresses/113500042.html?page=women",
    new_price: 99.9,
    old_price: 139.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwd71e1447/images/large/113500042/113500042-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "טופ אסימטרי לורקס",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/124604602.html?page=women",
    new_price: 49.9,
    old_price: 79.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw3d6e2ab3/images/large/124604602/124604602-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: 'שורט דגמ"ח',
    link: "https://www.renuar.co.il/women/clothing/jeans/214422049.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw430a058c/images/large/214422049/214422049-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: 'שורט דגמ"ח',
    link: "https://www.renuar.co.il/women/clothing/jeans/214422010.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwa2a5bb07/images/large/214422010/214422010-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: 'דגמ"ח שטוף רחב',
    link: "https://www.renuar.co.il/women/clothing/pants/224400202.html?page=women",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw0ab16531/images/large/224400202/224400202-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מכנסיים",
  },
  {
    name: "חולצת צווארון פלו פלו",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/223303801.html?page=women",
    new_price: 99.9,
    old_price: 159.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw4e317260/images/large/223303801/223303801-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "שמלת מקסי עם רקמות",
    link: "https://www.renuar.co.il/women/clothing/dresses/224572174.html?page=women",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwef8264bc/images/large/224572174/224572174-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "חצאית מידי דפוס ג'קארד",
    link: "https://www.renuar.co.il/women/clothing/skirts/214222445.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwfd5c114f/images/large/214222445/214222445-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "חצאית מידי רקמות",
    link: "https://www.renuar.co.il/women/clothing/skirts/224222974.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw2c9852ba/images/large/224222974/224222974-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "ג'ינס קרוס",
    link: "https://www.renuar.co.il/women/clothing/jeans/224461629.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw13ea920d/images/large/224461629/224461629-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "טישירט טדי ויסקוזה",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/224632801.html?page=women",
    new_price: 49.9,
    old_price: 69.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwda65658b/images/large/224632801/224632801-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "תיק רשת מכותנה",
    link: "https://www.renuar.co.il/women/accessories/bags_and_wallets/223761256.html?page=women",
    new_price: 19.9,
    old_price: 29.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw68de6486/images/large/223761256/223761256-2.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "תיקים",
  },
  {
    name: "שמלת מיני חתכים",
    link: "https://www.renuar.co.il/women/clothing/dresses/214506029.html?page=women",
    new_price: 99.9,
    old_price: 249.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw348472a4/images/large/214506029/214506029-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "ג'ינס חתכים מאיה",
    link: "https://www.renuar.co.il/women/clothing/jeans/214463229.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw9e894413/images/large/214463229/214463229-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "טישירט טדי ויסקוזה",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/224632802.html?page=women",
    new_price: 49.9,
    old_price: 69.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw474cca6f/images/large/224632802/224632802-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "טופ לאבי",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/214362701.html?page=women",
    new_price: 49.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwd7fc765d/images/large/214362701/214362701-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "שורט ג'ינס אור",
    link: "https://www.renuar.co.il/women/clothing/jeans/224459802.html?page=women",
    new_price: 99.9,
    old_price: 119.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwb60c4ed8/images/large/224459802/224459802-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "תיק נשיאה גדול פסים",
    link: "https://www.renuar.co.il/women/accessories/bags_and_wallets/224761047.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwefa5f3b6/images/large/224761047/224761047-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "תיקים",
  },
  {
    name: "חצאית סיטי גומי פסים",
    link: "https://www.renuar.co.il/women/clothing/skirts/124203311.html?page=women",
    new_price: 149.9,
    old_price: 179.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw4b4d2df1/images/large/124203311/124203311-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "סטרפלס תיפורים",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/214361229.html?page=women",
    new_price: 49.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwf8c785db/images/large/214361229/214361229-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "נעלי עקב ג'יג'י",
    link: "https://www.renuar.co.il/women/shoes/224752702.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwc0dc52d9/images/large/224752702/224752702-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "שורט ג'ינס אור",
    link: "https://www.renuar.co.il/women/clothing/jeans/224459822.html?page=women",
    new_price: 99.9,
    old_price: 119.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw5e7eae66/images/large/224459822/224459822-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "בגד גוף עם קאפים",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/124604502.html?page=women",
    new_price: 99.9,
    old_price: 129.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw06cb1cfd/images/large/124604502/124604502-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "ג'קט לין",
    link: "https://www.renuar.co.il/women/clothing/jackets-women/223141523.html?page=women",
    new_price: 149.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw7573bfee/images/large/223141523/223141523-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מעילים ג'קטים ווסטים",
  },
  {
    name: "מכנסי לין",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%9E%D7%9B%D7%A0%D7%A1%D7%99%D7%99%D7%9D-%D7%A7%D7%A6%D7%A8%D7%99%D7%9D/223423423.html?page=women",
    new_price: 99.9,
    old_price: 139.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwa0920f50/images/large/223423423/223423423-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מכנסיים קצרים",
  },
  {
    name: "חצאית ג'ינס פתח קידמי",
    link: "https://www.renuar.co.il/women/clothing/skirts/224250020.html?page=women",
    new_price: 149.9,
    old_price: 159.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw70096093/images/large/224250020/224250020-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "בגד גוף מנומר",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/124604334.html?page=women",
    new_price: 49.9,
    old_price: 99.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwb81fe34b/images/large/124604334/124604334-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "דגמ&#34;ח דריל בוטקאט לבן",
    link: "https://www.renuar.co.il/women/clothing/jeans/214459501.html?page=women",
    new_price: 99.9,
    old_price: 189.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw72a0f11c/images/large/214459501/214459501-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "חצאית מידי כיווצים",
    link: "https://www.renuar.co.il/women/clothing/skirts/223221523.html?page=women",
    new_price: 49.9,
    old_price: 119.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwef9bda3e/images/large/223221523/223221523-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "שמלת מיני איזי",
    link: "https://www.renuar.co.il/women/clothing/dresses/214503502.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwb22f1233/images/large/214503502/214503502-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "דריל ללי",
    link: "https://www.renuar.co.il/women/clothing/jeans/224454401.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw68dab4c5/images/large/224454401/224454401-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "מכנס קלאסי פס בצד",
    link: "https://www.renuar.co.il/women/clothing/pants/214405702.html?page=women",
    new_price: 99.9,
    old_price: 179.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw627dec15/images/large/214405702/214405702-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מכנסיים",
  },
  {
    name: "חצאית מעטפת ביז'ו",
    link: "https://www.renuar.co.il/women/clothing/skirts/124200602.html?page=women",
    new_price: 99.9,
    old_price: 129.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw49757ed8/images/large/124200602/124200602-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "שורט ג'ינס אור",
    link: "https://www.renuar.co.il/women/clothing/jeans/224459819.html?page=women",
    new_price: 99.9,
    old_price: 119.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwc4adf6eb/images/large/224459819/224459819-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "שמלת מיני איזי",
    link: "https://www.renuar.co.il/women/clothing/dresses/214503523.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwb3b6826e/images/large/214503523/214503523-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "חצאית סיטי גומי פסים",
    link: "https://www.renuar.co.il/women/clothing/skirts/124203304.html?page=women",
    new_price: 149.9,
    old_price: 179.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwd9b90fb8/images/large/124203304/124203304-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "ג'ינס קרוס",
    link: "https://www.renuar.co.il/women/clothing/jeans/224461619.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw7009dbda/images/large/224461619/224461619-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "שמלת מידי ביז'ו",
    link: "https://www.renuar.co.il/women/clothing/dresses/113502602.html?page=women",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw9d8d9739/images/large/113502602/113502602-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "מכנסי לין",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%9E%D7%9B%D7%A0%D7%A1%D7%99%D7%99%D7%9D-%D7%A7%D7%A6%D7%A8%D7%99%D7%9D/223423442.html?page=women",
    new_price: 99.9,
    old_price: 139.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw64a6b424/images/large/223423442/223423442-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מכנסיים קצרים",
  },
  {
    name: "ג'קט לין",
    link: "https://www.renuar.co.il/women/clothing/jackets-women/223141542.html?page=women",
    new_price: 149.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwa4e52155/images/large/223141542/223141542-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מעילים ג'קטים ווסטים",
  },
  {
    name: "שמלת מידי ביז'ו",
    link: "https://www.renuar.co.il/women/clothing/dresses/113502681.html?page=women",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwda8392d3/images/large/113502681/113502681-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "נעלי עקב ג'יג'י",
    link: "https://www.renuar.co.il/women/shoes/224752756.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwd4a42a94/images/large/224752756/224752756-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "חולצת תחרות דידי",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/214370194.html?page=women",
    new_price: 99.9,
    old_price: 139.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw3e6b0e8a/images/large/214370194/214370194-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "שורט ג'ינס אור",
    link: "https://www.renuar.co.il/women/clothing/jeans/224459829.html?page=women",
    new_price: 99.9,
    old_price: 119.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw6c33c06c/images/large/224459829/224459829-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "חולצת מכופתרת קרופ עם אבנים",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/114303019.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwb10a7150/images/large/114303019/114303019-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "שמלת סאטן ניטים",
    link: "https://www.renuar.co.il/women/clothing/dresses/214500310.html?page=women",
    new_price: 99.9,
    old_price: 239.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw2bfd092c/images/large/214500310/214500310-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "LOVE -מארז תחתונים",
    link: "https://www.renuar.co.il/women/midseasonsale/223731057.html?page=women",
    new_price: 29.9,
    old_price: 29.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwe711e37d/images/large/223731057/223731057-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "SALE סוף עונה",
  },
  {
    name: "LEOPARD LOVE -מארז תחתונים",
    link: "https://www.renuar.co.il/women/midseasonsale/215730264.html?page=women",
    new_price: 39.9,
    old_price: 39.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwd69be957/images/large/215730264/215730264-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "SALE סוף עונה",
  },
  {
    name: "WILD AND FREE -מארז תחתונים",
    link: "https://www.renuar.co.il/women/midseasonsale/215730064.html?page=women",
    new_price: 39.9,
    old_price: 39.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwe250ec42/images/large/215730064/215730064-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "SALE סוף עונה",
  },
  {
    name: "שלישיית עקביות איימי",
    link: "https://www.renuar.co.il/women/midseasonsale/222732211.html?page=women",
    new_price: 29.9,
    old_price: 29.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwe3b07768/images/large/222732211/222732211-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "SALE סוף עונה",
  },
  {
    name: "PORCELAIN -מארז תחתונים",
    link: "https://www.renuar.co.il/women/midseasonsale/223730918.html?page=women",
    new_price: 29.9,
    old_price: 29.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw17c4451e/images/large/223730918/223730918-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "SALE סוף עונה",
  },
  {
    name: "טיץ שליץ קדמי",
    link: "https://www.renuar.co.il/women/clothing/pants/214402005.html?page=women",
    new_price: 49.9,
    old_price: 129.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwa4a518c4/images/large/214402005/214402005-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מכנסיים",
  },
  {
    name: "שלישיית גרביים גבוהות",
    link: "https://www.renuar.co.il/women/midseasonsale/213732203.html?page=women",
    new_price: 29.9,
    old_price: 29.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwfb35418a/images/large/213732203/213732203-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "SALE סוף עונה",
  },
  {
    name: "סטרפלס דנים קרופ",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/214360922.html?page=women",
    new_price: 49.9,
    old_price: 139.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw048dcca2/images/large/214360922/214360922-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "HEARTS -מארז תחתונים",
    link: "https://www.renuar.co.il/women/midseasonsale/213731522.html?page=women",
    new_price: 29.9,
    old_price: 29.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw86193305/images/large/213731522/213731522-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "SALE סוף עונה",
  },
  {
    name: "סטרפלס דנים קרופ",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/214360929.html?page=women",
    new_price: 49.9,
    old_price: 139.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw10f730d2/images/large/214360929/214360929-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "גופיית ריקמה קרופ",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/223611810.html?page=women",
    new_price: 49.9,
    old_price: 59.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwcbf1cce5/images/large/223611810/223611810-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "שמלת מידי ביז'ו",
    link: "https://www.renuar.co.il/women/clothing/dresses/113502618.html?page=women",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw5f2f70ff/images/large/113502618/113502618-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "חולצת T שרוולים ארוכים רשת מבריקה",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/114601797.html?page=women",
    new_price: 49.9,
    old_price: 99.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw786ef59d/images/large/114601797/114601797-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "מחוך דריל נלי",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/224360794.html?page=women",
    new_price: 49.9,
    old_price: 129.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwa29af594/images/large/224360794/224360794-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "גינס קרוס ישר",
    link: "https://www.renuar.co.il/women/clothing/jeans/214461729.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw264fd0e5/images/large/214461729/214461729-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "חולצת לייקרה בייסיק שרוולי 3/4 מבריק",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/124600633.html?page=women",
    new_price: 49.9,
    old_price: 59.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwa4377685/images/large/124600633/124600633-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "מחוך דריל נלי",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/224360709.html?page=women",
    new_price: 49.9,
    old_price: 129.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dweb19a4a1/images/large/224360709/224360709-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "חצאית מעטפת ביז'ו",
    link: "https://www.renuar.co.il/women/clothing/skirts/124200616.html?page=women",
    new_price: 99.9,
    old_price: 129.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw0dcdf70b/images/large/124200616/124200616-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "חולצת לייקרה בייסיק שרוולי 3/4 מבריק",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/124600629.html?page=women",
    new_price: 49.9,
    old_price: 59.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw53751951/images/large/124600629/124600629-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "חצאית פאם שליץ וקפלים",
    link: "https://www.renuar.co.il/women/clothing/skirts/123201909.html?page=women",
    new_price: 49.9,
    old_price: 159.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw89b2315b/images/large/123201909/123201909-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "מחוך פאייטים",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/124301975.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw6b83cf0e/images/large/124301975/124301975-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "גופיית ריקמה קרופ",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/223611819.html?page=women",
    new_price: 49.9,
    old_price: 59.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwbcdc1f56/images/large/223611819/223611819-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "סט קרופ כפתור זהב ש. ארוך",
    link: "https://www.renuar.co.il/women/clothing/jackets-women/223140802.html?page=women",
    new_price: 99.9,
    old_price: 219.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwef7bfadc/images/large/223140802/223140802-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מעילים ג'קטים ווסטים",
  },
  {
    name: "גינס קרוס ישר",
    link: "https://www.renuar.co.il/women/clothing/jeans/214461722.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw45a135ba/images/large/214461722/214461722-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "ווסט סאטן",
    link: "https://www.renuar.co.il/women/clothing/jackets-women/114100097.html?page=women",
    new_price: 99.9,
    old_price: 179.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw1f62257c/images/large/114100097/114100097-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מעילים ג'קטים ווסטים",
  },
  {
    name: "ג'קט בטנת פסים",
    link: "https://www.renuar.co.il/women/clothing/jackets-women/224142010.html?page=women",
    new_price: 149.9,
    old_price: 229.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw6fabc0a1/images/large/224142010/224142010-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מעילים ג'קטים ווסטים",
  },
  {
    name: "מנהטן סלידר",
    link: "https://www.renuar.co.il/women/shoes/223754902.html?page=women",
    new_price: 49.9,
    old_price: 89.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw0fe7b1ad/images/large/223754902/223754902-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "חולצת לייקרה בייסיק שרוולי 3/4 מבריק",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/124600602.html?page=women",
    new_price: 49.9,
    old_price: 59.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwfb743f48/images/large/124600602/124600602-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "חולצת לייקרה וי תחרה",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/214689409.html?page=women",
    new_price: 49.9,
    old_price: 89.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw27867daa/images/large/214689409/214689409-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "נעל עקב בלינג",
    link: "https://www.renuar.co.il/women/shoes/213753615.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw3b746054/images/large/213753615/213753615-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "שמלת סאטן ניטים",
    link: "https://www.renuar.co.il/women/clothing/dresses/214500350.html?page=women",
    new_price: 99.9,
    old_price: 239.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw81423678/images/large/214500350/214500350-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "מכנסי גארדן",
    link: "https://www.renuar.co.il/women/clothing/jeans/224454509.html?page=women",
    new_price: 99.9,
    old_price: 179.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw5b1803cb/images/large/224454509/224454509-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "סקיני אריאל",
    link: "https://www.renuar.co.il/women/clothing/jeans/214461519.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwfd344b6c/images/large/214461519/214461519-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "דגמח גומי רוכסנים",
    link: "https://www.renuar.co.il/women/clothing/jeans/214405220.html?page=women",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw9244ec21/images/large/214405220/214405220-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "חצאית מידי טוויל כיסים",
    link: "https://www.renuar.co.il/women/clothing/skirts/214223049.html?page=women",
    new_price: 149.9,
    old_price: 159.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwd1409e1b/images/large/214223049/214223049-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "סנדלי עקב אמילי",
    link: "https://www.renuar.co.il/women/shoes/215750302.html?page=women",
    new_price: 99.9,
    old_price: 189.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw87cd5391/images/large/215750302/215750302-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "שמלת מיני דנית",
    link: "https://www.renuar.co.il/women/clothing/dresses/122504262.html?page=women",
    new_price: 99.9,
    old_price: 299.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwebbe2780/images/large/122504262/122504262-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "מכנסי לייקרה ביז'ו",
    link: "https://www.renuar.co.il/women/clothing/pants/124400516.html?page=women",
    new_price: 99.9,
    old_price: 119.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwad2a8944/images/large/124400516/124400516-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מכנסיים",
  },
  {
    name: "שמלת 7/8 סילק",
    link: "https://www.renuar.co.il/women/clothing/dresses/213550102.html?page=women",
    new_price: 99.9,
    old_price: 229.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwd350010d/images/large/213550102/213550102-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "מחוך דמוי ג'ינס",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/124301820.html?page=women",
    new_price: 49.9,
    old_price: 99.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw2c12bca1/images/large/124301820/124301820-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "חולצה מכופתרת וולאן בצווארון",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/214302901.html?page=women",
    new_price: 99.9,
    old_price: 139.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw949ef529/images/large/214302901/214302901-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "חולצת מרין",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/213306002.html?page=women",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwe438c9cb/images/large/213306002/213306002-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "חולצת קרופ ריב מחשוף קולר",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/113607610.html?page=women",
    new_price: 49.9,
    old_price: 99.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwe9b789da/images/large/113607610/113607610-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "חצאית מידי כיווצים",
    link: "https://www.renuar.co.il/women/clothing/skirts/223221502.html?page=women",
    new_price: 49.9,
    old_price: 119.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwa685be57/images/large/223221502/223221502-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "טופ בד ריב",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/124603834.html?page=women",
    new_price: 49.9,
    old_price: 89.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw9c50e226/images/large/124603834/124603834-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "טופ קרפ סקובה",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/124602528.html?page=women",
    new_price: 99.9,
    old_price: 129.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw02d357e7/images/large/124602528/124602528-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "נעלי עקב ג'יה",
    link: "https://www.renuar.co.il/women/shoes/214751074.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwf7cfb26f/images/large/214751074/214751074-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "דריל ללי",
    link: "https://www.renuar.co.il/women/clothing/jeans/224454409.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw12acbd8f/images/large/224454409/224454409-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "מכנסי פסי סיכה עם שרשרת",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%9E%D7%9B%D7%A0%D7%A1%D7%99%D7%99%D7%9D-%D7%A7%D7%A6%D7%A8%D7%99%D7%9D/223201319.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw5c59f1aa/images/large/223201319/223201319-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מכנסיים קצרים",
  },
  {
    name: "נעלי עקב ג'יה",
    link: "https://www.renuar.co.il/women/shoes/214751002.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw4419a64c/images/large/214751002/214751002-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "מכנסי גארדן",
    link: "https://www.renuar.co.il/women/clothing/jeans/224454501.html?page=women",
    new_price: 99.9,
    old_price: 179.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw381b9d38/images/large/224454501/224454501-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "נעלי עקב בוני",
    link: "https://www.renuar.co.il/women/shoes/223753311.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw4a293c2b/images/large/223753311/223753311-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "נעל ג'ונס",
    link: "https://www.renuar.co.il/women/shoes/224751402.html?page=women",
    new_price: 99.9,
    old_price: 179.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwe2d22a0e/images/large/224751402/224751402-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "נעלי עקב מידנייט",
    link: "https://www.renuar.co.il/women/shoes/224752402.html?page=women",
    new_price: 99.9,
    old_price: 229.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw2e06a2f3/images/large/224752402/224752402-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "כפכפי קומפי",
    link: "https://www.renuar.co.il/women/shoes/224753402.html?page=women",
    new_price: 99.9,
    old_price: 179.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw8e8477ae/images/large/224753402/224753402-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: 'דגמ"ח תיפורים',
    link: "https://www.renuar.co.il/women/clothing/jeans/214403129.html?page=women",
    new_price: 99.9,
    old_price: 219.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw14d90b59/images/large/214403129/214403129-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "טופ רשת מבריקה",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/114601397.html?page=women",
    new_price: 49.9,
    old_price: 79.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw5d8a3bd3/images/large/114601397/114601397-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "ג'ינס רוני אן",
    link: "https://www.renuar.co.il/women/clothing/jeans/214451502.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwcfe7bf3b/images/large/214451502/214451502-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "חצאית מידי שילוב תחרה",
    link: "https://www.renuar.co.il/women/clothing/skirts/124203110.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw7dbde0f8/images/large/124203110/124203110-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "שמלת 7/8 סילק",
    link: "https://www.renuar.co.il/women/clothing/dresses/213550161.html?page=women",
    new_price: 99.9,
    old_price: 229.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwe1020997/images/large/213550161/213550161-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "טישירט חוט סמייל",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/224635601.html?page=women",
    new_price: 99.9,
    old_price: 119.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw6d0f9d46/images/large/224635601/224635601-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "חולצת קרושה אבנים",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/224680774.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwc436089c/images/large/224680774/224680774-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "חצאית מעטפת ג'נואה",
    link: "https://www.renuar.co.il/women/clothing/skirts/124202209.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw69fa161a/images/large/124202209/124202209-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "ג'ינס קרוס",
    link: "https://www.renuar.co.il/women/clothing/jeans/224461622.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwa6f5f2b2/images/large/224461622/224461622-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "נעל עקב בלינג",
    link: "https://www.renuar.co.il/women/shoes/213753602.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw1e34d2fa/images/large/213753602/213753602-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "חצאית מידי פאייטים",
    link: "https://www.renuar.co.il/women/clothing/skirts/124201775.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw6ea3a869/images/large/124201775/124201775-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "כפכפי פאלון",
    link: "https://www.renuar.co.il/women/shoes/224752574.html?page=women",
    new_price: 49.9,
    old_price: 99.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw25f1eb26/images/large/224752574/224752574-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "טישירט חוט ירח",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/224635502.html?page=women",
    new_price: 99.9,
    old_price: 119.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw8d44a434/images/large/224635502/224635502-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "חולצת איזבל קרפ",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/113301702.html?page=women",
    new_price: 99.9,
    old_price: 129.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwbee54ab6/images/large/113301702/113301702-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "סקיני דאנס",
    link: "https://www.renuar.co.il/women/clothing/jeans/214461622.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwf3227b66/images/large/214461622/214461622-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "סקיני אריאל",
    link: "https://www.renuar.co.il/women/clothing/jeans/214461502.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw63078e65/images/large/214461502/214461502-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "נעלי עקב רייז",
    link: "https://www.renuar.co.il/women/shoes/223756002.html?page=women",
    new_price: 99.9,
    old_price: 179.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw4914f8f8/images/large/223756002/223756002-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "סטרפלס צמוד עם מטאלי",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/114601809.html?page=women",
    new_price: 49.9,
    old_price: 119.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw6c50c6cf/images/large/114601809/114601809-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "ג'ינס ישר מון",
    link: "https://www.renuar.co.il/women/clothing/jeans/224461829.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwb5211b0c/images/large/224461829/224461829-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "שמלת ריב שטופה",
    link: "https://www.renuar.co.il/women/clothing/dresses/224504216.html?page=women",
    new_price: 99.9,
    old_price: 159.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwd49ceb05/images/large/224504216/224504216-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: 'שמלת מידי סטרפלס דגמ"ח',
    link: "https://www.renuar.co.il/women/clothing/dresses/124500016.html?page=women",
    new_price: 99.9,
    old_price: 189.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwd98ac82a/images/large/124500016/124500016-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "סניקרס טוויד",
    link: "https://www.renuar.co.il/women/shoes/213752263.html?page=women",
    new_price: 99.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw230c7aa2/images/large/213752263/213752263-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "שמלת מאש פרינט ג'ינס",
    link: "https://www.renuar.co.il/women/clothing/dresses/124500420.html?page=women",
    new_price: 99.9,
    old_price: 179.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwe7d6c1ab/images/large/124500420/124500420-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "שמלות",
  },
  {
    name: "דריל ללי",
    link: "https://www.renuar.co.il/women/clothing/jeans/224454402.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw75f5d117/images/large/224454402/224454402-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: 'דגמ"ח שטוף רחב',
    link: "https://www.renuar.co.il/women/clothing/pants/224400249.html?page=women",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw3377fc71/images/large/224400249/224400249-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מכנסיים",
  },
  {
    name: "טופ לאבי",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/214362719.html?page=women",
    new_price: 49.9,
    old_price: 149.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw0c0b76a9/images/large/214362719/214362719-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "ג'קט לינן פסים",
    link: "https://www.renuar.co.il/women/clothing/jackets-women/114101510.html?page=women",
    new_price: 149.9,
    old_price: 179.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw581b2a75/images/large/114101510/114101510-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מעילים ג'קטים ווסטים",
  },
  {
    name: "ג'ינס רוני אן",
    link: "https://www.renuar.co.il/women/clothing/jeans/214451519.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwe6769e80/images/large/214451519/214451519-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "חצאית מקסי",
    link: "https://www.renuar.co.il/women/clothing/skirts/123202023.html?page=women",
    new_price: 49.9,
    old_price: 139.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw9f437a72/images/large/123202023/123202023-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "סקיני דאנס",
    link: "https://www.renuar.co.il/women/clothing/jeans/214461602.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw35806c9f/images/large/214461602/214461602-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "טופ ג'ינס סטסי",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/224361022.html?page=women",
    new_price: 49.9,
    old_price: 129.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw6a6df309/images/large/224361022/224361022-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "חצאית מידי רשת אבנים",
    link: "https://www.renuar.co.il/women/clothing/skirts/214271002.html?page=women",
    new_price: 149.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwcbb94b9c/images/large/214271002/214271002-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "נעלי עקב בוני",
    link: "https://www.renuar.co.il/women/shoes/223753302.html?page=women",
    new_price: 99.9,
    old_price: 169.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwcdd80c0d/images/large/223753302/223753302-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "נעלי עקב רייז",
    link: "https://www.renuar.co.il/women/shoes/223756075.html?page=women",
    new_price: 99.9,
    old_price: 179.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw9e311d5e/images/large/223756075/223756075-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "חולצת תחרות דידי",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/214370110.html?page=women",
    new_price: 99.9,
    old_price: 139.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dwbffec1b3/images/large/214370110/214370110-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "כפכפי פאלון",
    link: "https://www.renuar.co.il/women/shoes/224752502.html?page=women",
    new_price: 49.9,
    old_price: 99.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw5d1e9d93/images/large/224752502/224752502-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "נעליים",
  },
  {
    name: "גופייה מנומרת",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/124604434.html?page=women",
    new_price: 49.9,
    old_price: 89.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw183a9c08/images/large/124604434/124604434-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "מכנס תחרה סט",
    link: "https://www.renuar.co.il/women/clothing/pants/214409502.html?page=women",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw17149f9d/images/large/214409502/214409502-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "מכנסיים",
  },
  {
    name: "מארז תחתונים מילי",
    link: "https://www.renuar.co.il/women/midseasonsale/222731104.html?page=women",
    new_price: 29.9,
    old_price: 29.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw0423f1ad/images/large/222731104/222731104-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "SALE סוף עונה",
  },
  {
    name: "חצאית מעטפת וולאן",
    link: "https://www.renuar.co.il/women/clothing/skirts/124200362.html?page=women",
    new_price: 99.9,
    old_price: 139.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw9ee0239c/images/large/124200362/124200362-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חצאיות",
  },
  {
    name: "דגמח סקיני גינס",
    link: "https://www.renuar.co.il/women/clothing/jeans/214460802.html?page=women",
    new_price: 99.9,
    old_price: 199.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw17cd0638/images/large/214460802/214460802-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "ג'ינסים",
  },
  {
    name: "בגד גוף עם קאט-אאוט",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/123601623.html?page=women",
    new_price: 49.9,
    old_price: 129.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw7f4c546f/images/large/123601623/123601623-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "טישירט magic",
    link: "https://www.renuar.co.il/%D7%A0%D7%A9%D7%99%D7%9D/%D7%91%D7%92%D7%93%D7%99%D7%9D/%D7%97%D7%95%D7%9C%D7%A6%D7%95%D7%AA/223636901.html?page=women",
    new_price: 49.9,
    old_price: 99.9,
    picture_links: [
      "https://www.renuar.co.il/dw/image/v2/BDRJ_PRD/on/demandware.static/-/Sites-renuar-master/default/dw24a41450/images/large/223636901/223636901-1.jpg?sh=500",
    ],
    company: "RENUAR",
    category: "חולצות",
  },
  {
    name: "מכנסי קרגו ארוכים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03487?color=6",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w034870004-11718612776.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "גופיית קרופ ריב",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16052?color=4",
    new_price: 34.93,
    old_price: 49.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160520001-11718615889.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "מכנסי כדורסל קצרים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16008?color=4",
    new_price: 69.93,
    old_price: 99.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160080001-11718615671.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "גופיית ריב בהדפס פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16051?color=4",
    new_price: 34.93,
    old_price: 49.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160510001-11718615802.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "מכנסיים קצרים בטקסטורה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16019?color=467",
    new_price: 97.93,
    old_price: 139.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160190021-11718006908.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "קרדיגן סריג עם כיסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10094?color=4",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100940001-11718004738.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "טופ ביקיני משולשים בהדפס פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w05409?color=4",
    new_price: 41.93,
    old_price: 59.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w054090001-11718020089.jpg",
    ],
    company: "FOX",
    category: "SWIMWEAR",
  },
  {
    name: "חולצה מכופתרת עם כיסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16049?color=4",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160490001-11718008215.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "חולצה מכופתרת עם כיסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16047?color=5",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160470010-11718008148.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "גופיית פסים קרופ",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10050?color=4",
    new_price: 27.93,
    old_price: 39.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100500001-11718004628.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "מכנסיים קצרים עם הדפס",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16011?color=20",
    new_price: 55.93,
    old_price: 79.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160110010-11718006665.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "חולצה מכופתרת פסים עם כיסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16048?color=9429",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160482010-11718008191.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "מכנסיים קצרים בהדפס פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16012?color=8602",
    new_price: 55.93,
    old_price: 79.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160122075-11718006837.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "מכנסיים קצרים עם הדפס",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16014?color=2148",
    new_price: 55.93,
    old_price: 79.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160140027-11718006874.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "שמלת מקסי ריב פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16055?color=4",
    new_price: 69.93,
    old_price: 99.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160550001-11718009104.jpg",
    ],
    company: "FOX",
    category: "DRESS",
  },
  {
    name: "מכנסיים קצרים עם הדפס",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16010?color=10",
    new_price: 55.93,
    old_price: 79.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160100005-11718006602.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "גופיית ריב עם כפתורים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10020?color=4",
    new_price: 27.93,
    old_price: 39.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100200001-11718004536.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "שמלת מקסי ריב",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16054?color=4",
    new_price: 69.93,
    old_price: 99.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160540001-11718008735.jpg",
    ],
    company: "FOX",
    category: "DRESS",
  },
  {
    name: "שמלת שלבים עם גב פתוח",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16059?color=4",
    new_price: 118.93,
    old_price: 169.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160590001-11718009125.jpg",
    ],
    company: "FOX",
    category: "DRESS",
  },
  {
    name: "מכנסי אריג בהדפס פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16078?color=10",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160780005-11718010135.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "גופיית ריב פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10030?color=4",
    new_price: 27.93,
    old_price: 39.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100300001-11718004610.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "גופיית ריב בייסיק",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16050?color=4",
    new_price: 34.93,
    old_price: 49.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160500001-11718008389.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "טופ ביקני בטקסטורה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10069?color=8411",
    new_price: 41.93,
    old_price: 59.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100692039-11713686883.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "תחתוני ביקני בטקסטורה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w05401?color=8411",
    new_price: 34.93,
    old_price: 49.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w054012039-11713684904.jpg",
    ],
    company: "FOX",
    category: "LINGERIE",
  },
  {
    name: "מכנסיים קצרים עם הדפס",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16018?color=6",
    new_price: 90.93,
    old_price: 129.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160180004-11717057628.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "חולצה מכופתרת בטקסטורה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16102?color=9783",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w161022268-11717057270.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "מכנסיים קצרים בהדפס פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16017?color=9429",
    new_price: 90.93,
    old_price: 129.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160172010-21717066566.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "מכנסיים קצרים עם גומי מותן",
    link: "https://www.terminalx.com//brands/fox-main/fox/w05356?color=4",
    new_price: 97.93,
    old_price: 139.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w053560001-11716983169.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "מכנסיים קצרים עם גומי מותן",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16016?color=4",
    new_price: 90.93,
    old_price: 129.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160160001-11716977109.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "מכנסי טרנינג ארוכים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w00271?color=18",
    new_price: 41.97,
    old_price: 139.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w002710020-11716874557.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "חולצת טי שירט בייסיק ארוכה / Plus Size",
    link: "https://www.terminalx.com//brands/fox-main/fox/z34606?color=10",
    new_price: 26.97,
    old_price: 89.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/3/z346060005-11716874570.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "גופיית קרופ ריב",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10149?color=4",
    new_price: 27.93,
    old_price: 39.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w101490001-11719476598.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "תחתוני ביקיני בהדפס פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w05410?color=4",
    new_price: 34.93,
    old_price: 49.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w054100001-11718020125.jpg",
    ],
    company: "FOX",
    category: "LINGERIE",
  },
  {
    name: "מכנסיים קצרים עם תיפורים מודגשים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16015?color=8",
    new_price: 41.93,
    old_price: 59.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160150011-11718615768.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "גופיית קרופ ריב",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16053?color=4",
    new_price: 41.93,
    old_price: 59.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160530001-11715584873.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "טייץ בייקר ריב",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16009?color=4",
    new_price: 41.93,
    old_price: 59.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160090001-11715584817.jpg",
    ],
    company: "FOX",
    category: "TIGHTS",
  },
  {
    name: "ג'ינס בגזרת MOM",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16001?color=9",
    new_price: 118.93,
    old_price: 169.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160010006-11715517137.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "ג'ינס ANKLE",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16003?color=9",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160030006-11715516806.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "ג'ינס קצר עם סרט קשירה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15962?color=8621",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159622012-11715582594.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "גופיית ריב בהדפס פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10023?color=2148",
    new_price: 27.93,
    old_price: 39.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100230027-11715585833.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "ג'ינס קצר בגזרה גבוהה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15963?color=4",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159630001-11715583611.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "ג'ינס בגזרת SLIM",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15997?color=8621",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159972012-11715520375.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "ג'ינס קצר בגזרה גבוהה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15969?color=9",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159690006-11715582953.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "ג'ינס בגזרת STRAIGHT",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16002?color=9",
    new_price: 118.93,
    old_price: 169.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160020006-11715517593.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "מכנסי קורדרוי קצרים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03931?color=18",
    new_price: 62.93,
    old_price: 89.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w039310020-11715581336.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "גופיית ריב קרופ",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10027?color=4",
    new_price: 27.93,
    old_price: 39.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100270001-11715518880.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "מכנסי אריג עם גומי מותן",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16077?color=8924",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160772003-11715518733.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "טי שירט קרופ חלקה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03847?color=4",
    new_price: 27.93,
    old_price: 39.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w038470001-11715519716.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "מכנסי טרנינג פוטר סקיני",
    link: "https://www.terminalx.com//brands/fox-main/fox/z53911?color=9091",
    new_price: 35.97,
    old_price: 119.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/5/z539112006-11657181964_1.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "כותונת לילה עם הדפס סטיץ'",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03947?color=467",
    new_price: 83.93,
    old_price: 119.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w039470021-11713773120.jpg",
    ],
    company: "FOX",
    category: "PYJAMAS",
  },
  {
    name: "סט פיג'מה 2 חלקים בהדפס פופקורן",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03945?color=22",
    new_price: 83.93,
    old_price: 119.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w039450007-11713686671.jpg",
    ],
    company: "FOX",
    category: "PYJAMAS",
  },
  {
    name: "ג'ינס בגזרת סקיני",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15996?color=9",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159960006-11712221995.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "חולצה מכופתרת חלקה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10055?color=10",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100550005-11712217980.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "ג'ינס בגזרת סקיני",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15998?color=4",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159980001-11712222768.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "חולצה מכופתרת בהדפס פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10053?color=467",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100530021-11712140204.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "מכנסי אריג עם כיסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03952?color=4",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w039520001-11712222404.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "תחתוני ביקיני בטקסטורת ריב",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15734?color=9810",
    new_price: 34.93,
    old_price: 49.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w157342061-11707121533.jpg",
    ],
    company: "FOX",
    category: "LINGERIE",
  },
  {
    name: "תחתוני ביקיני בטקסטורת ריב",
    link: "https://www.terminalx.com//brands/fox-main/fox/w05407?color=4",
    new_price: 34.93,
    old_price: 49.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w054070001-11707119405.jpg",
    ],
    company: "FOX",
    category: "LINGERIE",
  },
  {
    name: "טופ ביקיני משולשים בטקסטורת ריב",
    link: "https://www.terminalx.com//brands/fox-main/fox/w05406?color=4",
    new_price: 41.93,
    old_price: 59.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w054060001-11707118805.jpg",
    ],
    company: "FOX",
    category: "SWIMWEAR",
  },
  {
    name: "ג'ינס ארוך בגזרה מתרחבת",
    link: "https://www.terminalx.com//brands/fox-main/fox/z74788?color=9",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z747880006-11687427135.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "ג'ינס ארוך בגזרה מתרחבת",
    link: "https://www.terminalx.com//brands/fox-main/fox/z74790?color=4",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z747900001-11687427164.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "גופיית ריב פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10033?color=4",
    new_price: 27.93,
    old_price: 39.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100330001-11706426404.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "גופיית ריב קרופ וי",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10031?color=4",
    new_price: 27.93,
    old_price: 39.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100310001-11709816222.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "גופיית ריב קרופ",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10006?color=4",
    new_price: 27.93,
    old_price: 39.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100060001-11709816167.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "מכנסיים קצרים עם תיפורים מודגשים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03950?color=4",
    new_price: 41.93,
    old_price: 59.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w039500001-11700469357.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "מכנסיים טרנינג ארוכים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03951?color=4",
    new_price: 97.93,
    old_price: 139.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w039510001-11700468164.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "קרדיגן סריג ארוך",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03583?color=4",
    new_price: 56.97,
    old_price: 189.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w035830001-11693299977.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "קפוצון עם הדפס של קספר",
    link: "https://www.terminalx.com//brands/fox-main/fox/z33984?color=10",
    new_price: 47.97,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/3/z339840005-11655034315.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט קפוצ'ון קרופ",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78475?color=70",
    new_price: 56.97,
    old_price: 189.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z784750013-11705841168.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט קפוצ'ון קרופ",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78474?color=4",
    new_price: 56.97,
    old_price: 189.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z784740001-11695129262.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט קפוצ'ון קרופ",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78926?color=4",
    new_price: 29.97,
    old_price: 99.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z789260001-11695130871.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט קפוצ'ון קרופ",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78927?color=8",
    new_price: 29.97,
    old_price: 99.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z789270011-11703164750.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט עם צווארון גבוה",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78815?color=100",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z788150012-11695130371.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט קפוצ'ון חלק",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78816?color=4",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z788160001-11694351628.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט קפוצ'ון עם כיס",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78820?color=100",
    new_price: 35.97,
    old_price: 119.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z788200012-11707202509.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט קפוצ'ון קרופ עם הדפס כיתוב",
    link: "https://www.terminalx.com//brands/fox-main/fox/z79013?color=20",
    new_price: 38.97,
    old_price: 129.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z790130017-11689768031.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "מכנסי טרנינג עם כיסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78472?color=4",
    new_price: 47.97,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z784720001-11695129159.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "גרביונים דמוי צמר",
    link: "https://www.terminalx.com//brands/fox-main/fox/z04262?color=7",
    new_price: 19.95,
    old_price: 39.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/0/z042620008-11699186563.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "מכנסי ג'ינס קצרים עם פרחים רקומים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z60537?color=9",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/6/z605370006-11675854292.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "גופיית קרופ טאי דאי",
    link: "https://www.terminalx.com//brands/fox-main/fox/w04726?color=467",
    new_price: 20.97,
    old_price: 69.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w047260021-11689856473.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "מכנסי טרנינג קצרים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z60514?color=6",
    new_price: 17.97,
    old_price: 59.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/6/z605140004-11681812889.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "סווטשירט עם הדפס כיתוב",
    link: "https://www.terminalx.com//brands/fox-main/fox/z95811?color=18218",
    new_price: 47.97,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/9/z958118263-11706436148.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט קפוצ'ון עם כיס",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78819?color=9048",
    new_price: 35.97,
    old_price: 119.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z788190026-11706431505.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סוודר בהדפס",
    link: "https://www.terminalx.com//brands/fox-main/fox/z87347?color=4",
    new_price: 50.97,
    old_price: 169.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/8/z873470001-11688039441.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט קפוצ'ון פרווה",
    link: "https://www.terminalx.com//brands/fox-main/fox/z87353?color=22",
    new_price: 59.97,
    old_price: 199.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/8/z873530007-11700469957.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "מכנסי טרנינג עם הדפס",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78714?color=2185",
    new_price: 29.97,
    old_price: 99.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z787140030-11693300257.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "סווטישרט קרופ עם רוכסן חצי",
    link: "https://www.terminalx.com//brands/fox-main/fox/z87334?color=11119",
    new_price: 29.97,
    old_price: 99.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/8/z873342057-11701253565.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "סווטשירט קפוצ'ון בייסיק",
    link: "https://www.terminalx.com//brands/fox-main/fox/z01404?color=4",
    new_price: 35.97,
    old_price: 119.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/0/z014040001-11706435104.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "קרדיגן לונגליין ללא רכיסה",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78945?color=4",
    new_price: 53.97,
    old_price: 179.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z789450001-11701264784.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "מכנסי טרנינג בהדפס מיקי מאוס",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78702?color=22",
    new_price: 41.97,
    old_price: 139.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z787020007-11696766448.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "סווטשירט קפוצ'ון עם הדפס סנופי",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78708?color=70",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z787080013-11696768284.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט קפוצ'ון קרופ עם הדפס HARVARD",
    link: "https://www.terminalx.com//brands/fox-main/fox/z79011?color=9048",
    new_price: 38.97,
    old_price: 129.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z790110026-11689767971.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "מכנסי טרנינג עם פאץ' כיתוב",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78500?color=4",
    new_price: 41.97,
    old_price: 139.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z785000001-11696767198.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "סווטשירט קפוצ'ון עם הדפס לוני טונס",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78711?color=20290",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z787112903-11696768337_1.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סריג קרופ חלק",
    link: "https://www.terminalx.com//brands/fox-main/fox/z83056?color=4",
    new_price: 35.97,
    old_price: 119.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/8/z830560001-11694688645.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "מכנסי ג'ינס בגזרת FLARE",
    link: "https://www.terminalx.com//brands/fox-main/fox/z74791?color=4",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z747910001-11687681233.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "ג'ינס סקיני ארוך",
    link: "https://www.terminalx.com//brands/fox-main/fox/z74795?color=4",
    new_price: 41.97,
    old_price: 139.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z747950001-11687427192_1.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "מכנסי פוטר בסגנון קרגו",
    link: "https://www.terminalx.com//brands/fox-main/fox/z74670?color=6",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z746700004-11688033920_2.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "סט פיג'מה טי שירט ומכנסיים ארוכים עם הדפס לוני טונס",
    link: "https://www.terminalx.com//brands/fox-main/fox/z74685?color=9048",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z746850026-11691581171.jpg",
    ],
    company: "FOX",
    category: "PYJAMAS",
  },
  {
    name: "סט פיג'מה טי שירט ומכנסיים ארוכים בהדפס טוויטי",
    link: "https://www.terminalx.com//brands/fox-main/fox/z74703?color=116",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z747030015-11691581282.jpg",
    ],
    company: "FOX",
    category: "PYJAMAS",
  },
  {
    name: "קרדיגן בטקסטורה עם צווארון גבוה",
    link: "https://www.terminalx.com//brands/fox-main/fox/z74391?color=6",
    new_price: 53.97,
    old_price: 179.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z743910004-11681821569.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "מכנסיים קצרים עם כיסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z60573?color=5",
    new_price: 41.97,
    old_price: 139.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/6/z605730010-11681818771.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "טי שירט קרופ בהדפס פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z59883?color=6",
    new_price: 14.97,
    old_price: 49.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/5/z598830004-11690792465.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "ג'ינס קצר עם פרחים רקומים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z60538?color=8621",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/6/z605382012-11681818719.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "מכנסי טרנינג קצרים טאי דאי",
    link: "https://www.terminalx.com//brands/fox-main/fox/z60304?color=2148",
    new_price: 26.97,
    old_price: 89.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/6/z603040027-11681812581.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "ג'ינס בגזרה ישרה",
    link: "https://www.terminalx.com//brands/fox-main/fox/z53086?color=8621",
    new_price: 51.96,
    old_price: 129.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/5/z530862012-11681726155.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "תחתוני ביקיני קלאסיים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z60199?color=100",
    new_price: 14.97,
    old_price: 49.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/6/z601990012-11675761198.jpg",
    ],
    company: "FOX",
    category: "LINGERIE",
  },
  {
    name: "מכנסיים קצרים עם כיסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z60572?color=17762",
    new_price: 41.97,
    old_price: 139.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/6/z605720001-11676973948.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "תחתוני ביקיני ריב",
    link: "https://www.terminalx.com//brands/fox-main/fox/z60194?color=81",
    new_price: 14.97,
    old_price: 49.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/6/z601940016-11675759922.jpg",
    ],
    company: "FOX",
    category: "LINGERIE",
  },
  {
    name: "חולצה עם כפתורים בטקסטורת קרפ",
    link: "https://www.terminalx.com//brands/fox-main/fox/z60587?color=18",
    new_price: 47.97,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/6/z605870020-11675861977.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "ג'ינס קצר Mom",
    link: "https://www.terminalx.com//brands/fox-main/fox/z60345?color=9",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/6/z603450006-11675588098.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "סריג קרופ חלק",
    link: "https://www.terminalx.com//brands/fox-main/fox/z34584?color=9048",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/3/z345840026-11668600692.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "טי שירט עם תבליט",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15002?color=4",
    new_price: 20.97,
    old_price: 69.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w150020001-11696505176.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "טי שירט ריב עם כפתורים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z53606?color=6",
    new_price: 19.77,
    old_price: 65.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/5/z536060004-11676967756.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "סווטשירט קפוצ'ון עם הדפס דיסני / Plus Size",
    link: "https://www.terminalx.com//brands/fox-main/fox/z33343?color=9",
    new_price: 47.97,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/3/z333430006-11671355512.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט קרופ עם הדפס כיתוב / Plus Size",
    link: "https://www.terminalx.com//brands/fox-main/fox/z34610?color=100",
    new_price: 20.97,
    old_price: 69.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/3/z346100012-11671357010.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט קפוצ'ון עם הדפס",
    link: "https://www.terminalx.com//brands/fox-main/fox/z33959?color=106",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/3/z339590002-11669106321.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סריג קרופ עם צווארון גבוה",
    link: "https://www.terminalx.com//brands/fox-main/fox/z34575?color=8411",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/3/z345752039-11710240041.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "סווטשירט פוטר בייסיק / בנות",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78628?color=2148",
    new_price: 19.47,
    old_price: 64.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z786280027-11672899331.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "מכנסי טרנינג בשילוב רוכסן / PLUS SIZE",
    link: "https://www.terminalx.com//brands/fox-main/fox/z34613?color=17762",
    new_price: 47.97,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/3/z346130018-11662623819.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "סווטשירט קרופ בשילוב הדפס",
    link: "https://www.terminalx.com//brands/fox-main/fox/z33450?color=2882",
    new_price: 20.97,
    old_price: 69.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/3/z334500028-11655034017.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סווטשירט עם צווארון גולף",
    link: "https://www.terminalx.com//brands/fox-main/fox/x61105?color=8256",
    new_price: 62.93,
    old_price: 89.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/x/6/x611050031-11610885809.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "קפוצון עם הדפס של Felix the cat",
    link: "https://www.terminalx.com//brands/fox-main/fox/z33986?color=8",
    new_price: 47.97,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/3/z339860011-11655034333.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "ג'ינס קצר עם סיומת גזורה",
    link: "https://www.terminalx.com//brands/fox-main/fox/z60548?color=9",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/6/z605480006-11681986463_1.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "ג'ינס סקיני ארוך",
    link: "https://www.terminalx.com//brands/fox-main/fox/z74792?color=9",
    new_price: 41.97,
    old_price: 139.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z747920006-11687428479.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "קרדיגן פוטר חלק",
    link: "https://www.terminalx.com//brands/fox-main/fox/z79018?color=4",
    new_price: 53.97,
    old_price: 179.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z790180001-11689768110.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סוודר בטקסטורת צמות",
    link: "https://www.terminalx.com//brands/fox-main/fox/z87339?color=100",
    new_price: 53.97,
    old_price: 179.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/8/z873390012-11689834264.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "מכנסי טרנינג עם כיסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z34005?color=17762",
    new_price: 47.97,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/3/z340050018-11660212464.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "סווטשירט קפוצ'ון עם כיתוב רקום YALE",
    link: "https://www.terminalx.com//brands/fox-main/fox/z79012?color=8602",
    new_price: 38.97,
    old_price: 129.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z790122075-11689768002.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "סוודר קרופ סרוג",
    link: "https://www.terminalx.com//brands/fox-main/fox/z79617?color=8924",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z796172003-11686440222.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "מכנסי טרנינג מתרחבים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78506?color=100",
    new_price: 29.97,
    old_price: 99.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z785060012-11694088398_1.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "מכנסי ג'ינס ברמודה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15965?color=9",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159650006-11712224259.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "ג'ינס בגזרת MOM",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16000?color=22",
    new_price: 118.93,
    old_price: 169.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160000007-11712140503.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "מכנסי טרנינג עם כיסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78473?color=70",
    new_price: 47.97,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z784730013-11705584343.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "מכנסי ג'ינס קצרים קורדרוי",
    link: "https://www.terminalx.com//brands/fox-main/fox/z94161?color=18",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/9/z941610020-11684160726.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "סט פיג'מה 2 חלקים עם הדפס 101 דלמטים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15722?color=4",
    new_price: 97.93,
    old_price: 139.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w157220001-11713778516.jpg",
    ],
    company: "FOX",
    category: "PYJAMAS",
  },
  {
    name: "ג'ינס קצר בגזרה נמוכה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15968?color=9",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159680006-11715584036.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "שמלת מידי טי שירט",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10108?color=4",
    new_price: 83.93,
    old_price: 119.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w101080001-11718005571.jpg",
    ],
    company: "FOX",
    category: "DRESS",
  },
  {
    name: "מכנסי קרגו ארוכים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z73796?color=8602",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z737960004-11688033776_2.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "ג'ינס ארוך בגזרת WIDE",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16004?color=8621",
    new_price: 118.93,
    old_price: 169.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160042012-11715587214.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "מכנסי ג'ינס קצרים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15966?color=4",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159660001-11712223923.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "ג'ינס בגזרת MOM",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15999?color=4",
    new_price: 118.93,
    old_price: 169.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159990001-11712222239.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "חצאית ג'ינס מידי כיסים מודגשים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16068?color=22",
    new_price: 125.93,
    old_price: 179.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160680007-11718009969.jpg",
    ],
    company: "FOX",
    category: "SKIRTS",
  },
  {
    name: "מכנסי ג'ינס קצרים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15964?color=10",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159640005-11712139322.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "חצאית מידי עם הדפס פסים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16056?color=4",
    new_price: 69.93,
    old_price: 99.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160560001-11717057654.jpg",
    ],
    company: "FOX",
    category: "SKIRTS",
  },
  {
    name: "סט פיג'מה בהדפס סנופי",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16020?color=22",
    new_price: 83.93,
    old_price: 119.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160200007-11716802288.jpg",
    ],
    company: "FOX",
    category: "PYJAMAS",
  },
  {
    name: "סווטשירט קרופ עם הדפס כיתוב",
    link: "https://www.terminalx.com//brands/fox-main/fox/z74773?color=9048",
    new_price: 20.97,
    old_price: 69.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z747730026-11706430304.jpg",
    ],
    company: "FOX",
    category: null,
  },
  {
    name: "מכנסי טרנינג עם הדפס כיתוב",
    link: "https://www.terminalx.com//brands/fox-main/fox/z74769?color=70",
    new_price: 26.97,
    old_price: 89.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z747690001-11689757789.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "חצאית מידי עם כפתורים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10077?color=6",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100770004-11715520678.jpg",
    ],
    company: "FOX",
    category: "SKIRTS",
  },
  {
    name: "חצאית ג'ינס מידי כיסים מודגשים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16069?color=4",
    new_price: 125.93,
    old_price: 179.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160690001-11718009999.jpg",
    ],
    company: "FOX",
    category: "SKIRTS",
  },
  {
    name: "תחתוני ביקיני בגזרה גבוהה / נשים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w05408?color=4",
    new_price: 34.93,
    old_price: 49.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w054080001-11701769658.jpg",
    ],
    company: "FOX",
    category: "LINGERIE",
  },
  {
    name: "ג'ינס קצר עם רקמת לבבות",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15959?color=8621",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159592012-11718005812.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "שמלת מידי מכופתרת",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16074?color=8924",
    new_price: 125.93,
    old_price: 179.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160742003-11715159777.jpg",
    ],
    company: "FOX",
    category: "DRESS",
  },
  {
    name: "שמלת מידי מכופתרת",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16075?color=10",
    new_price: 125.93,
    old_price: 179.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160750005-11715159959.jpg",
    ],
    company: "FOX",
    category: "DRESS",
  },
  {
    name: "שמלת מידי סרבל קורדרוי",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15711?color=18",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w157110020-11713772832.jpg",
    ],
    company: "FOX",
    category: "DRESS",
  },
  {
    name: "שמלה מכופתרת עם חגורת קשירה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w05364?color=4",
    new_price: 125.93,
    old_price: 179.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w053640001-11717051442.jpg",
    ],
    company: "FOX",
    category: "DRESS",
  },
  {
    name: "מכנסי טרנינג פוטר עם קשירה",
    link: "https://www.terminalx.com//brands/fox-main/fox/x37189?color=1582",
    new_price: 32.97,
    old_price: 109.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/x/3/x371892065-11655019049.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "פיג'מה ריב 2 חלקים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03946?color=4",
    new_price: 83.93,
    old_price: 119.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w039460001-11713686701.jpg",
    ],
    company: "FOX",
    category: "PYJAMAS",
  },
  {
    name: "טופ ביקיני בטקסטורת ריב",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10068?color=4",
    new_price: 41.93,
    old_price: 59.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100680001-11707121260.jpg",
    ],
    company: "FOX",
    category: "SWIMWEAR",
  },
  {
    name: "ג'ינס קצר בגזרה גבוהה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15967?color=9",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159670006-11715581874.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "סט פיג'מה 2 חלקים בהדפס דובוני אכפת לי",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03958?color=10",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w039580005-11713773404.jpg",
    ],
    company: "FOX",
    category: "PYJAMAS",
  },
  {
    name: "חצאית ג'ינס מידי כיסים מודגשים",
    link: "https://www.terminalx.com//brands/fox-main/fox/w16065?color=9",
    new_price: 125.93,
    old_price: 179.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w160650006-11718009288.jpg",
    ],
    company: "FOX",
    category: "SKIRTS",
  },
  {
    name: "גופיית קרופ כיתוב רקום",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03053?color=10",
    new_price: 20.97,
    old_price: 69.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w030530005-11693214891.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "טופ בקיני משולשים בטקסטורת ריב",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15733?color=9810",
    new_price: 41.93,
    old_price: 59.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w157332061-11718020164.jpg",
    ],
    company: "FOX",
    category: "SWIMWEAR",
  },
  {
    name: "ג'ינס קצר עם גומי מותן",
    link: "https://www.terminalx.com//brands/fox-main/fox/w15970?color=4",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w159700006-11715582413.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "סט פיג'מה 2 חלקים עם הדפס פו הדב",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03949?color=8",
    new_price: 125.93,
    old_price: 179.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w039490011-11713773150.jpg",
    ],
    company: "FOX",
    category: "PYJAMAS",
  },
  {
    name: "חולצה מכופתרת טטרה",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10054?color=4",
    new_price: 104.93,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100540001-11712224221.jpg",
    ],
    company: "FOX",
    category: "SHIRTS",
  },
  {
    name: "גופיית ריב",
    link: "https://www.terminalx.com//brands/fox-main/fox/w10024?color=9048",
    new_price: 34.93,
    old_price: 49.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/1/w100240026-11703765104.jpg",
    ],
    company: "FOX",
    category: "TANKTOP",
  },
  {
    name: "סט פיג'מה 2 חלקים בהדפס מיני מאוס",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03959?color=10",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w039590005-11713777005.jpg",
    ],
    company: "FOX",
    category: "PYJAMAS",
  },
  {
    name: "מכנסי טרנינג חלקים",
    link: "https://www.terminalx.com//brands/fox-main/fox/z78499?color=4",
    new_price: 35.97,
    old_price: 119.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/7/z784990001-11695129367.jpg",
    ],
    company: "FOX",
    category: "PANTS",
  },
  {
    name: "מכנסי קרגו דמוי ג'ינס",
    link: "https://www.terminalx.com//brands/fox-main/fox/w03485?color=8621",
    new_price: 111.93,
    old_price: 159.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/w/0/w034852012-11717996325.jpg",
    ],
    company: "FOX",
    category: "JEANS",
  },
  {
    name: "שמלת מקסי קומות",
    link: "https://www.terminalx.com//brands/fox-main/fox/z11684?color=4",
    new_price: 44.97,
    old_price: 149.9,
    picture_links: [
      "https://media.terminalx.com/pub/media/catalog/product/cache/92af6b9c945798a7e3b64d91033084b3/z/1/z116840007-11645144320.jpg",
    ],
    company: "FOX",
    category: "DRESS",
  },
];
